<template>
	<div class="mx-auto">
		<div class="grid grid-cols-12 h-screen">
			<div class="col-span-2 flex flex-col text-white left">
				<Sidebar />
			</div>
			<div class="grid col-span-10 p-4">
				<div class="flex flex-col">

					<div class="flex flex-none flex-row px-4 pt-3">
						<div class="mr-auto bg-blue-300"></div>
						<div class="ml-auto">
							<button class="rounded px-4 py-2 text-white bg-blue-500 hover:bg-blue-600"><i class="fa fa-plus mr-2"></i>Add</button>
						</div>
					</div>

					<div class="flex-1 p-4">
						<table class="table-fixed w-full">
							<thead>
								<tr>
									<th class="py-2 w-20">ID</th>
									<th class="py-2 w-auto">Name</th>
									<th class="py-2 w-auto">Email</th>
									<th class="py-2 w-36">Mobile</th>
									<th class="py-2 w-40">City</th>
									<th class="py-2 w-32">ZIP</th>
									<th class="py-2 w-auto">Created</th>
									<th class="w-auto"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="customer in customers" :key="customer.id">
									<td class="py-2 px-2 text-center">{{ customer.id }}</td>
									<td class="py-2 px-2">{{ customer.name_first + " " + customer.name_last }}</td>
									<td class="py-2 px-2">{{ customer.email }}</td>
									<td class="py-2 px-2 text-center">+{{ customer.mobile }}</td>
									<td class="py-2 px-2 text-center">{{ customer.city }}</td>
									<td class="py-2 px-2 text-center">{{ customer.zip }}</td>
									<td class="py-2 text-center">{{ new Date(customer.timestamps.created * 1000).toLocaleString("en-US", { timeZone: "Asia/Colombo" }) }}</td>
									<td class="px-2 text-center">
										<router-link :to="{ path: '/customer/' + customer.id }"><button class="py-1 px-2 font-semibold text-xs rounded text-white bg-green-500">VIEW</button></router-link>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue"

export default {
	name: "Customers",
	components: { Sidebar },

	mounted() {
		this.$store.dispatch("customers/list", { page: 1 })
	},

	computed: {
		customers() { return this.$store.state.customers.customers }
	}
}
</script>

<style scoped>
.left {
	background-color: #4338CA;
}

table thead tr th {
	background-color: #f6f8fc;
	border: 2px solid #dee2e6;
}

table tbody tr {
	border: 2px solid #dee2e6;
}

table thead tr th {
	color: #054FC7;
}

table tbody tr:hover {
	background-color: #eeeeee;
}
</style>
