<template>
    <div class="flex flex-non py-4 px-3">
        <h1 class="font-bold text-2xl text-center w-full">Octiq</h1>
    </div>
    <div class="flex flex-grow py-3 px-3">
        <ul class="flex flex-col nav-items space-y-1 w-full">
            <router-link to="/items" exact active-class="active"><li class="text-lg rounded tracking-wide p-3">Items</li></router-link>
            <router-link to="/orders" exact active-class="active"><li class="text-lg rounded tracking-wide p-3">Orders</li></router-link>
            <router-link to="/customers" exact active-class="active"><li class="text-lg rounded tracking-wide p-3">Customers</li></router-link>
        </ul>
    </div>
    <div class="flex flex-none py-3 px-3 profile">
        <figure class="flex flex-row">
            <img class="rounded-full w-14" src="https://www.gravatar.com/avatar/1D9B51E7A09CCF132AC9EAADD965916C" />
            <div class="px-3">
                <h2 class="text-lg p-0 m-0">Shyamin Ayesh</h2>
                <router-link to="/logout"><h3 class="text-sm">Logout</h3></router-link>
            </div>
        </figure>
    </div>
</template>

<style scoped>
.nav-items li {
	cursor: pointer;
    color: #E0E7FF;
    font-family: "Noto Sans";
}

.nav-items a li:hover {
	cursor: pointer;
	background-color: #3730A3;
}

.nav-items a.active li {
    color: #FFFFFF;
    background-color: #3730A3;
}

.profile {
    background-color: #372ea5;
}
</style>
